@import '../../globalVars';
.form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // padding: 20px;
  min-width: 100%;
  > div {
    min-width: 100%;
    margin-bottom: 20px;
  }
  position: relative;
  top: 0;
  transition: top 0.25s;
}

.bottomRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
}
.goBtn {
  position: relative;
  right: -20px;
}

.backToLogin {
  font-size: 1rem;
  margin-right: 10px;
  cursor: pointer;
  margin-right: 0;
  position: relative;
  top: 2.6em;
  left: 0;
  @include defaultFocus;
  &:hover {
    text-decoration: underline;
  }
}