.camWrapper {
  position: relative;
  width: 100%;
  // min-height: 1024px;
  // canvas {
  //   width: 100%;
  //   height: 100px;
  // }
  overflow:hidden;
  video {

  }
}

.buttons {
  position: absolute !important;
  z-index: 999;
  left: 50%;
  transform: translate3d(-50%,0,0);
  bottom: 3vh;
  display: flex;
  flex-direction: row;
}

.backButton {
  background: #f50057 !important; //TODO USE THEME
  color: #000;
  transform: scaleX(-1);
  margin-right: 30px !important;
  box-shadow: 4px 4px 15px -4px rgba(0,0,0,1);
}

.captureBtn {
  background: #fff;
  border-radius: 9999px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  box-shadow: 4px 4px 15px -4px rgba(219,0,219,1);
  transition: transform 0.1s;
  &:hover{
    transform: scale(1.1)
  }
}

.vidCropper {
  display: block;
  width: 1024px;
  height: 1024px;
  overflow: hidden;
  position: absolute;
  // transform: translate3d(-50%,-50%,0);
  video {
    position: absolute;
    transform: scale(-1,1);
  }
}

