.DrippingCanvas {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  cursor: none;

}


.srcVid {
  width: 100%;
  height: auto;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  @media (min-aspect-ratio: 16/9) {
    height: auto;
    width: 100%;
  }
  @media (max-aspect-ratio: 16/9) {
    height: 100%;
    width: auto;
  }
}

.canvasBase {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  cursor: none;
}

.maskCanvas {
  composes: canvasBase;
  display: none !important;
}

.displayCanvas {
  composes: canvasBase;
  z-index: 1000;
  opacity: 1;
}

.svgWrapper {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -999;
}