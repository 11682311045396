.WritingClient {
  
}


.WritingClientControls {
  position: relative;
}
.input {
  width: 100%;
}

.bottomRow{
  padding-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.15;
  transition: opacity 0.3s;
}

.bottomRowActive {
  composes: bottomRow;
  opacity: 1;
}

html body div .clearBtn {
  position: absolute;
  bottom: 6.9rem;
  right: 2.5rem;
  width: 2.0rem;
  height: 2.0rem;
  fill: #bb0000;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}

html body div .clearBtnActive {
  position: absolute;
  bottom: 6.9rem;
  right: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  fill: #bb0000;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
}