.mainGridWrapper {
  // margin: 10px -16px 0 -16px !important;
  // padding: 0 !important;
  // border: solid 2px #f00 !important;
  z-index: 2;
  position: relative;
}

.modalBackdrop {

}