@import '../../globalVars';
.Moderation {
  margin-top: $topNavHeight;
  color: #fff;
}
.contentBox{
  max-width: 80%;
  height: 50vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  font-size: 1.2em;
  pointer-events:none;
  * {
    max-height: 100%;
    max-width: 100%;
  }
}
.ObjectCarousel {
  overflow-x: auto;
  position: relative;
  max-width: 100%;
}
.ObjectCarouselInner {
  display: inline-flex;
  height:20vh;
  padding-left: 1.5em;
  padding-right: 1.5em;
  img {
    cursor: pointer;
    height: 100%;
    width: auto;
    margin-right: 1.5em;
  }
}

.buttonGroup {
  margin: 1.5em 0;
}

.contentPara{
  aspect-ratio: 1;
  border: solid 1px rgba(255,255,255,0.15);
  margin: 0;
  padding: 0.6em;
  text-align: left;
  overflow: hidden;
  font-size: 1.5em;
  cursor: pointer;
  margin-right: 1.1em;
}