.VerificationForm {
  min-width: 300px;
  text-align: center;
  h3 {
    margin-bottom: 1.5em;
  }
}
.SMSVerificationDigit {
  width: 100%;
  font-size: 3rem;
  text-align: center;
  border: solid 1px #000;
  margin-bottom: 20px;
  letter-spacing: 0.3em;
}

.submitVerificationCode {
  border-radius: 50%;
  margin-left: 20px;
  background: #42adf5 !important;
  -webkit-box-shadow: 4px 4px 14px 0px rgba(0,0,0,0.51) !important;
  -moz-box-shadow: 4px 4px 14px 0px rgba(0,0,0,0.51) !important;
  box-shadow: 4px 4px 14px 0px rgba(0,0,0,0.51) !important;
  &:hover {
    background: #3eaffa !important;
  }
}

.codeMismatch {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-bottom: 2rem;
  > div {
    padding: 0.5rem 2rem 0.8rem;
    background: #ffc0bf;
    border: solid 1.5px #f00;
    > svg {
      fill: #75100d;
      position: relative;
      top: 5px;
      margin-right: 0.5rem;
    }
  }
}

.verificationSuccess {
  display: flex;
  align-items: center;
  flex-wrap:wrap;
  width: 100%;
  button {
    background: #0b0;
    color: #fff;
    font-weight: 600;
    font-size: 1.1rem;
    text-transform: none;
    padding: 0.6rem 1.3rem;
    &:hover {
      background: #0d0;
    }
  }
}

.greenCheck {
  fill: #0d0 !important;
  margin-right: 20px;
  width: 2rem;
  height: 2rem;
  position: relative;
  top: 10px;
}

.resend {
  position: relative;
  top: 0.8em;
  font-size: 0.9em;
  cursor: pointer;
  display: block;
  text-align: left;
  left: 0;
  // @include defaultFocus;
  &:hover {
    text-decoration: underline;
  }
}

.bottomRow {
  display: block;
  text-align: center;
  width: 100%;
  margin-top: 1.3em;
}
.goBtn {
  text-align: center;
  position: relative;
}