@import '../../../globalVars';

.SpiritMakerUI {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.finalCanvasWrapper{
  position: absolute;
  top:0;
  left: -999999vw;
  z-index: 9999;
  transform: translate3d(0,0,0);
  // overflow: hidden;
}
.finalCanvasWrapperOpenCamOpen{
  composes: finalCanvasWrapper;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) translate(0px,2px);
}

.finalCanvasWrapperOpenCamClosed{
  composes: finalCanvasWrapper;
  position: unset;
  transform: unset;
}

.finalCanvasButtons {
  position: absolute;
  bottom: 3vh;
  left: 50%;
  transform: translate3d(-50%,0,0);
  button:nth-child(1) {
    background: #f50057; //TODO USE THEME
    color: #000;
    transform: scaleX(-1);
    margin-right: 30px;
  }
  button:nth-child(2) {
    background: #3f51b5; //TODO USE THEME
    color: #fff;
  }
}

.initialButtons {
  justify-content: center;
  display: flex;
  flex-direction: row; 
  padding: 25px 0;

  form button, button:nth-child(2){
    background: #3f51b5; //TODO USE THEME
    color: #fff;

    
  }
  button:nth-child(2) {
    box-shadow: 4px 4px 15px -4px rgba(0,0,0,1);
    transform: scale(1.4);
  }
  form {
    margin-right:25px;
    box-shadow: 4px 4px 15px -4px rgba(0,0,0,0.7);
    border-radius: 999px;
    transform: scale(1);
    position: relative;
    top: 0.5em;
  } 
}


.modalInnerClass{
  padding: 0;
}

.modalOuterClass {
  width: 98%;
}

.uploadConfirmModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%,-50%,0);
  z-index: 9999;
  background: rgba(0,0,0,0.7);
  width: 100%;
  padding: 8% 0;
  p {
    font-size: 1.9em;
    padding: 0;
    margin: 0;
  }
  @media only screen and (max-width: $breakpointSm) {
    font-size: 12px;
  }
}

.sendButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 2em;
  @media only screen and (max-width: $breakpointSm) {
    flex-direction: column;
    align-items: center;
    > div:first-of-type {
      margin-bottom: 40px;
      margin-top: 20px;
    }
    > div:last-of-type {
      margin-bottom: 40px;
    }
  }
}