

.createBurnablesForm {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  label{
    font-size: 1.4rem;
    text-align: left;
    margin-bottom: 0.7rem;
  }
  input {
    font-size: 1.5rem;
    padding: 0.5em;
    margin-bottom: 1.2rem;
  }
}

.buttonDiv {
  margin-top: 1.2rem;
  display: flex;
  justify-content: center;
}

.QRGen {
  display: flex;
  label {
    font-size: 1.1rem;
    margin: .7rem 0 .7rem 0.15rem;
  }
  text-align:left;
}

.codeWrapper {
  // min-height: 400px;
  position: relative;
  top: 0;
  transition: top 0.3s;
  // canvas {
  //   width: 100%;
  // }
}

.codeWrapperInner{
  width: 100%;
  canvas {
    width: 100%;
  }
}

.optionsForm{
  display: flex;
  flex-direction: column;
  width: 100%;
  label {
    font-size: 1.1rem;
    margin: .7rem 0 .7rem 0.15rem;
    letter-spacing: 0.11rem;
  }
}

.optionsFormHidden{
  composes: optionsForm;
  height: 0 !important;
  overflow: hidden;
  width: 0 !important;
}
.textField {

}

.sectionLabel {
  display: block;
}

.stackedAccordion {
  display: flex;
  flex-direction: column;
}

html body div .gradientColorsSection,html body div .gradientDetailsSection {
  display: flex;
  flex-direction: column;
  border: solid 1px rgba(255,255,255,0.13);
  padding: 1rem;
  & > div {
    margin-top: 1rem;
  }
}


.outputSvg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: block;
  margin-top: 68px;
  // width: 500px;
  // height: 500px;
}