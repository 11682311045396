@import '../../globalVars';
.QRStamper{
  display: flex;
  flex-direction: column;
  margin-top: $topNavHeight;
  input {
    color: #fff;
    padding: 10px;
  }
  h3 {
    color: #fff;
  }
  iframe {
    min-height: 600px;
    width: 85%;
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.dropZone {
  border: dashed 2px rgba(255,255,255,0.5);
  width: 300px;
  height: 200px;
  background: rgba(255,255,255,0.05);
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.page {
  width: 50%;
}

.page1 {
  composes: page;
}

.page2 {
  composes: page;
}

.offsetForm{
  display: flex;
  color: #fff;
  flex-direction: column;
  input {
    background: rgba(0,0,0,0.5)
  }
}

.codesZipForm{
  color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: solid 0.5px rgba(255,255,255,0.5);
  padding: 10px 0;
  margin: 20px 0;
  input[type='text']{
    color:#000;
  }
}

.buttonRow {
  composes: row;
  display: flex;
  flex-direction: column;
  border: solid 1px rgba(255,255,255,0.5);
  padding: 30px;
  label {
    color: #fff;
  }
  input {
    color: #000;
    text-align: center;
    margin: 25px 0;
    font-size: 1.4rem;
  }
}