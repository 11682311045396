.DecoButton {
  display: inline-flex;
  // height: 100px;
  text-align: left;
  // height: 2em;
  cursor: pointer;
  position: relative;
  -webkit-touch-callout: none;  
  &:focus, &:focus-visible {
    border: dashed 1px rgba(0,0,0,0.5) !important;
    margin-top: -1px;
    margin-right: -1px;
    outline: none;
  }
}

.textArea {
  display: inline-block;
  height: 100%;
  color: #fff;
  position: relative;
  background-image: url('../../Img/DecoButtonLightMiddle.svg');
  background-size: 100% 100%;
  // padding: 0 1.2em;
  overflow: hidden;
  // font-size: 2.4em;
  -webkit-touch-callout: none;  
  span {
    font-weight: 800;
    letter-spacing: .03em;
    color: #000;
    display: block;
    margin: 0;
    // padding: 0;
    position: relative;
    top: 50%;
    transform: translateY(-56%);
    text-shadow: 2px 1px 0px rgba(255, 255, 255, 0.1);
    -webkit-touch-callout: none;  
    pointer-events: none;
    text-align: center;
  }
  // img {
  //   height: 100%;
  //   width: 100%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  // }
}

.textAreaDark {
  composes: textArea;
  background-image: url('../../Img/DecoButtonDarkMiddle.svg') !important;
}


.endcap {
  height: 100%;
  display: inline-block;
  position: relative;
  img {
    height: 100%;
  }
}

.leftEndcap {
  composes: endcap;
  left: 1px;
}
.rightEndcap {
  composes: endcap;
  left: -1px;
}

.noSelecty {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}