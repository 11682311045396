@import './globalVars';
/* @import url("https://use.typekit.net/quo6frh.css"); */
* {
  font-family: haboro-contrast-normal, sans-serif;
  font-weight: 400;
  font-style: normal;
}

html, body {
  height: 100%;
  width: 100%;
  background: $baseBGColor;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* dat.gui styles */
.dg.ac{z-index: 9999 !important;}
.dg.main {
  float: left !important;
  margin-left: 45%;
}

/* remove here logo watermark from map */
.H_logo {
  opacity: 0 !important;
}



 /* global css vars */

 :root {
   $topNavHeight: 168px !global;
 }