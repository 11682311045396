.IndirectControlActual{
  display: flex;
  width: 800px;
  height: 600px;
  justify-content: center;
  align-items: center;
  background: #333;
}
.IndirectControlActualOn {
  composes: IndirectControlActual;
  background: #0f0;
}