@import '../../globalVars';

.TopNav {
  -webkit-box-shadow: 1px 10px 17px -2px rgba(0,0,0,0.38) !important;
  -moz-box-shadow: 1px 10px 17px -2px rgba(0,0,0,0.38) !important;
  box-shadow: 1px 10px 17px -2px rgba(0,0,0,0.38) !important;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 999 !important;
  height: var(--top-nav);
}

.drawer {
  // > div {
  //   overflow: auto;
  // }
  span {
    z-index: 3;
  }
}

.menuCloseButton {
  display: block;
  position: absolute;
  top: 39px;
  right: 9px;
  z-index: 3;
  font-size: 1.7em;
  padding: 0.2em 0.4em;
  background: rgba(0,0,0,0.02);
  cursor: pointer;
  &:hover {
    background: rgba(0,0,0,0.04);
  }
}

.toolbar {
  justify-content:space-between;
  background-image: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)), url('../../Img/Tex/blackLeather2.jpg');
  height: $topNavHeight;
}

.menuBG {
  display: block;
  background: #fff; 
  z-index: 1;
  margin-top: 30px;
  margin-left: 35px;
  margin-bottom: 30px;
  min-height: calc(100% - 60px);
  position: relative;
  overflow-y: auto;
  padding-bottom: 150px;
  box-sizing: border-box;
}

.topMenuCorner {
  position: absolute;
  top: 26px;
  left: 29px;
  width: 5.2em;
  z-index: 99;
}

.bottomMenuCorner {
  width: 5.2em;
  bottom: 24px;
  position: absolute;
  left: 28px;
  transform: scaleY(-1);
  z-index: 9;
}

html body div .printedMenuItem {
  z-index: 3;
  position: relative;
  top: 55px;
  left: 45px;
  white-space: pre-wrap;
  font-family: 'haboro-contrast-normal', sans-serif;
}

html body div .signedInAs {
  font-size: 1.85em;
  font-family: 'haboro-contrast-normal', sans-serif;
  padding-top: 70px;
  padding-bottom: 0;
  margin-left: 30px;
  margin-bottom: 10px;
  display: block;
}


html body div .userName{
  flex-direction: row-reverse;
  font-size: 1.45em;
  word-break: break-all;
  justify-content: flex-end;
  margin-left: 70px;
  margin-right: 28px;
}


.menuHeader {
  z-index: 3;
  padding-right: 20px;
  h1 {
    font-size: 2em;
  }
  img {
    width: 50%;
    position: relative;
    top: -15px;
  }
}

.restrictedMenuHeader {
  composes: menuHeader;
  h1 {
    color: #9c0606;
  }
}


.menuLists {
  z-index: 3;
  // left: 35px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  // width: 100%;
  text-align: right;
  position: relative;
  top: 50px;
  ul {
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    li {
      a {
        color: #000;
        font-size: 1.5em;
        text-decoration: none;
        padding: 5px 5px;
      }
    }
  }
}


.navSubhead {
  font-size: 1.4em;
  font-weight: 700;
  margin-right: 0.6em;
}