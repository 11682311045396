@import '../../../globalVars';
.ComeInsideScreen{
  width: 100%;
  height: 80vh;
  max-width: 1211px;
  margin: 0 auto;
  @media only screen and (max-width: $breakpointSm) {
    height: 75vh;
  }
}

.welcome {
  width: 85%;
  height: auto;
  margin: 10px auto 100px;
  display: block;
  z-index: 2;
  position: relative;
  @media only screen and (max-width: $breakpointSm) {
    width: 110%;
    margin-left: -5%;
    margin-bottom: 0;
  }
}

.title {
  font-size: 3.7em;
  text-align: center;
  z-index: 2;
  position: relative;
  text-shadow: 5px 5px 6px rgba(0, 0, 0, 0.7);
  @media only screen and (max-width: $breakpointSm) {
    font-size: 2.7em;
  }
}

.footerLogo {
  display: block;
  width: 200px;
  height: auto;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  @media only screen and (max-width: $breakpointSm) {
    width: 150px;
  }
}