
.HomeScreen {
  width: 100%;
  height: 100%;
}

.BarLogo {
  max-width: 95%;
  margin-top: 10px;
}

.title {
  margin-top: 0;
  font-size: 1.8rem;
  text-shadow: 6px 6px 4px rgba(0,0,0,0.7);
  span {
    display: block;
    margin-bottom: .5rem;
  }
}