.FixLocPerms {
  h3 {
    font-size: 1.8em;
    margin: 0;
  }
  p {
    font-size: 1.2em;
    line-height: 1.6em;
    letter-spacing: 0.04em;
    margin-bottom: 1em;
  }
  a {
    color: #cc1111;
    &:hover {
      color: #cc3333;
    }
  }
}