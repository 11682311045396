.inputForm {
  position: relative;
  overflow: hidden;
}

.fileInput {
  display: block;
  position: absolute;
  top: -1.5em;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 999;
}