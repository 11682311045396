$topNavHeight: 68px;
$baseBGColor: #111;
@mixin defaultFocus {
  &:focus, &:focus-visible {
    border: dashed 1px rgba(0,0,0,0.5) !important;
    outline: none;
  }
}

$breakpointXs: 0px;
$breakpointSm: 600px;
$breakpointMd: 960px;
$breakpointLg: 1280px;
$breakpointXl: 1920px;
