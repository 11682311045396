@import '../../../globalVars';
.GoldBorderModal {
    display: block;
    width: calc(100% - 80px);
    position: absolute;
    left: 50%;
    top: 90px;
    @media only screen and (max-width: $breakpointSm) {
      top: 75px;
    }
    display: inline-flex;
    border-radius: 0px;
    color: #fff;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(132deg, #654302 0%,#f7c972 12%,#644202 25%,#f7c972 38%,#6a490a 52%,#f7c972 66%,#694809 77%,#f7c972 88%,#6f4d0c 100%) 1;
    transform: translate(-50%,0);
    box-shadow: 9px 10px 18px -9px rgba(255,255,255,0.09);
    max-height: calc(95vh - 68px); 
    z-index: 99;
}
.modalInner {
  border: solid 1px rgba(211, 168, 168, 0.23);
  width: 100%;
  height: auto;
  min-height: 100%;
  background: linear-gradient(132deg, rgba(30,30,30,0.45) 0%,rgba(0,0,0,0.44) 100%); 
  padding: 2rem;
  overflow-x: hidden;
}