.ExperienceControlRelay {
  border: solid 6px;
  transition: border 1s, background-color 0.5s;
  background-color: transparent;
  position: relative;
}

.ExperienceControlRelayIdle{
  composes: ExperienceControlRelay;
  transition: border 1s, background-color 0.5s;
  border-color: rgba(100,100,100,0.25);
  background-color: rgba(100,100,100,0.13);
}

.ExperienceControlRelayUserConnected{
  composes: ExperienceControlRelay;
  transition: border 1s, background-color 0.5s;
  border-color: rgba(0,126,128,0.25);
  background-color: rgba(0,126,128,0.13);
}

.ExperienceControlRelayActive{
  composes: ExperienceControlRelay;
  transition: none;
  border-color: rgba(0,255,0,0.4);
  background-color: rgba(0,255,0,0.1);
}

.ExperienceControlRelayLockout{
  composes: ExperienceControlRelay;
  transition: border 1s, background-color 0.5s;
  border-color: rgba(246,0,255,0.25);
  background-color: rgba(246,0,255,0.15);
}

html body div .configBtn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  svg {
    fill: #fff;
  }
}

.configMenu {
  background: rgba(0,0,0,1);
  padding: 2em;
  ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    padding: 2em;
    li {
      display: flex;
      justify-content: space-around;
    }
  }
  
}

.expNameHeader {
  color: #fff;
  font-size: 1.6em;
}

.lockoutGroup {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  svg {
    margin-right: 0.4em;
    opacity: 0.8;
    cursor: pointer;
  }
  span {
    font-size: 1.1em;
    font-weight: 100;
    cursor: pointer;
  }
}

.kickGroup {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  color: #fff;
  cursor: pointer;
  svg {
    width: 30px;
    height: auto;
    fill: #fff !important;
    g {
      fill: #fff !important;
    }
    margin-right: 0.4em;
    opacity: 0.8;
    cursor: pointer;
  }
  span {
    font-size: 1.1em;
    font-weight: 100;
    cursor: pointer;
  }
}