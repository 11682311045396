@import '../../globalVars';
.login {
  min-width: 310px;
  @media only screen and (max-width: $breakpointSm) {
    min-width: 230px;  
  }
  h1 {
    font-size: 1.6em;
    margin: 0.2em 0;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // padding: 20px;
  min-width: 100%;
  > div {
    min-width: 100%;
    margin-bottom: 20px;
  }
  position: relative;
  top: 0;
  transition: top 0.25s;
}

.bottomRow {
  display: block;
  text-align: center;
  width: 100%;
}
.goBtn {
  text-align: center;
  position: relative;
}
 .forgotPassword {
  font-size: 0.7rem;
  cursor: pointer;
  margin-right: 0;
  position: relative;
  top: 2em;
  left: 1px;
  display: block;
  text-align: left;
  @include defaultFocus;
  &:hover {
    text-decoration: underline;
  }
}


.forgotPasswordFormTopRow {
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: flex-end;
}

.forgotPasswordFormBottomRow {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.verifyResetPasswordForm {
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 20px;
  }
}

.passwordFocused {
  @media only screen and (max-width: $breakpointSm) {
    top: -150px;  
 }
  
}