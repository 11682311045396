@import '../../globalVars';

.BatchPushBurnables{
  margin-top: $topNavHeight;
  margin-top: 68px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  input {
    color: #fff;
    padding: 10px;
  };
  h1 {
    color: #fff;
  }
}
.dropZone {
  border: dashed 2px rgba(255,255,255,0.5);
  width: 300px;
  height: 200px;
  background: rgba(255,255,255,0.1);
  width: 95%;
}