@import '../../../globalVars';
.NoDriveScreen {
  text-align: center;
}

.header {
  margin: -0.8em 0 0.5em;
  position: relative;
  font-size: 5em;
  font-weight: 600;
  text-shadow: 8px 8px 20px rgba(160,0,0,0.87);
  @media only screen and (max-width: $breakpointSm) {
    font-size: 4em;
  }
}

.para {
  font-size: 1.7em;
  em {
    text-decoration: underline;
  }
}

.form {
  margin-top: 4em;
  span {
    font-size: 1.7em;
    line-height: 1.05em;
  }
}

.crunch {
  max-width: 115%;
}

.checkboxCol {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.spanCol {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
}

.bottomRow {
  display: flex;
  margin-top: 2em;
  justify-content: center;
  margin-bottom: 25em;
}

.shakeCheck {
  border: solid 10px #f00 !important;
}