@import '../../../globalVars';
.LeatherTabbedModal {
  display: block;
  position: absolute;
  left: 50%;
  top: 150px;
  border-radius: 0px;
  color: #111;
  transform: translate(-50%, 0); 
  transition: top 0.5s;
  @media only screen and (max-width: $breakpointSm) {
    padding-bottom: 10em;
    top: 30px;
 }
}

.tabRow {
  display: flex;
  flex-direction: row-reverse;
}

.tab {
  font-size: 28px;  
  // padding: 0 1.1em;
  padding: 0.62em 1.5em 0.2em;
  background: #0d0d0d;
  border-radius: 10px 10px 0 0 ;
  cursor: pointer;
  background-image: url('../../../Img/Tex/blackLeather2b.jpg');
  background-position: bottom right;
  background-repeat: repeat;
  background-size: 1200px;
  position: relative;
  display: block;
  box-shadow: inset 8px 5px 23px 0px rgba(255,255,255,0.04), inset 0px -40px 45px -26px rgba(0,0,0,0.5);
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.28);
    border-radius: 10px 10px 0 0 ;
  }
}

.tabActive {
  composes: tab;
  // background-image: url('../../../Img/Tex/blackLeather2b.jpg');
  box-shadow: inset 8px 5px 23px 0px rgba(255,255,255,0.04), inset 0px -40px 45px -26px rgba(0,0,0,0.5);
  padding: 0.4em 1.5em;
  &::after {
    display: none;
  }
}

.tabTitleLink {
  display: block;
  background: linear-gradient(133deg, rgba(0,0,0,0.32) 0%, rgba(0,0,0,0.52) 100%), linear-gradient(133deg, rgba(243,251,173,1) 0%,rgba(207,181,130,1) 31%,rgba(179,152,70,1) 67%,rgba(255,210,122,1) 100%);
  background-size: cover;
  background-clip: text;
  color: rgba(0,0,0,0);
  font-size: 22px;
  text-shadow: 1px 2px 2px rgba(255, 255, 255, 0.1), -2px -2px 4px rgba(0,0,0,0.1);
}

.tabTitleLinkActive {
  background: linear-gradient(133deg, rgba(243,251,173,1) 0%,rgba(207,181,130,1) 31%,rgba(179,152,70,1) 67%,rgba(255,210,122,1) 100%);
  background-size: cover;
  background-clip: text;
  color: rgba(0,0,0,0);
  font-size: 25px;
  text-shadow: 1px 2px 2px rgba(255, 255, 255, 0.1), -2px -2px 4px rgba(0,0,0,0.1);
}

.contentRow {
  border-radius: 20px 0 20px 20px;
  background: #111;
  position: relative;
  top: -1px;
  overflow: hidden;
  background-image: url('../../../Img/Tex/blackLeather2.jpg');
  background-position: top right;
  background-repeat: repeat;
  background-size: 1000px;
  box-shadow: inset 8px 8px 9px 0px rgba(255,255,255,0.07),inset -8px -8px 9px 0px rgba(0,0,0,0.47);
}

.contentContainer {
  display: block;
  margin: 25px;
  background: #f0ede3;
}

.contentActive {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 2;
  padding: 11px 21px;
  box-shadow: inset 3px 3px 8px 0px rgba(0,0,0,0.21);
  min-width: 310px;
  @media only screen and (max-width: $breakpointSm) {
    min-width: 230px;
 }
  &::after {
    content: "";
    background-image: url('../../../Img/Tex/paperTexture.jpg');
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.3;
    z-index: 1;
  }
  > * {
    z-index: 10;
    position: relative;
  }
}
.contentInactive {
  display: none;
}