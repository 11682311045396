.GradientButton {
  display: inline-block;
  padding: 1em;
  border: solid 1px rgba(255,255,255,0.2);
  background: linear-gradient(to bottom, rgba(76,76,76,1) 0%, rgba(89,89,89,1) 5%, rgba(84,82,84,1) 10%, rgba(71,71,71,1) 15%, rgba(44,44,44,1) 23%, rgba(0,0,0,1) 51%, rgba(17,17,17,1) 60%, rgba(43,43,43,1) 76%, rgba(28,28,28,1) 91%, rgba(19,19,19,1) 100%);
  cursor:pointer;
  border: solid 1px rgba(0,0,0,0.5);
}

.GradientButtonDown {
  composes: GradientButton;
  background: linear-gradient(to bottom, rgba(19,19,19,1) 0%, rgba(28,28,28,1) 9%, rgba(43,43,43,1) 24%, rgba(17,17,17,1) 40%, rgba(0,0,0,1) 49%, rgba(44,44,44,1) 77%, rgba(71,71,71,1) 85%, rgba(84,82,84,1) 90%, rgba(89,89,89,1) 95%, rgba(76,76,76,1) 100%);
}

.text {
  display: block;
  text-align: center;
}