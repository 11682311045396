.LoadingModal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.7) !important;
  z-index: 9999;
}

.inner{
  position: absolute;
  left: 50%;
  top: 330px;
  transform: translateX(-50%);
  border-radius: 20px;
  background: rgba(0,0,0,0.7);
  width: 150px;
  height: 150px;
  box-sizing: content-box;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    height: 80%;
  }
}