.verificationSuccess {
  display: flex;
  align-items: center;
  flex-wrap:wrap;
  width: 100%;
  button {
    background: #0b0;
    color: #fff;
    font-weight: 600;
    font-size: 1.1rem;
    text-transform: none;
    padding: 0.6rem 1.3rem;
    &:hover {
      background: #0d0;
    }
  }
}

.greenCheck {
  fill: #0d0 !important;
  margin-right: 20px;
  width: 2rem;
  height: 2rem;
  position: relative;
  top: 10px;
}