@import '../../globalVars';
.signup {
  min-width: 310px;
  @media only screen and (max-width: $breakpointSm) {
    min-width: 230px;
  }
  h1 {
    font-size: 1.6em;
    margin: 0.2em 0;
  }
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // padding: 20px;
  min-width: 100%;
  > div {
    min-width: 100%;
    margin-bottom: 20px;
  }
  position: relative;
  top: 0;
  transition: top 0.25s;
  @media only screen and (min-width: $breakpointSm) {
    top: 0px !important;
 }
  // label {
  //   margin-bottom: 5px;
  // }
  // input {
  //   font-size: 1.2rem;
  //   padding: 5px;
  // }
  // button {
  //   margin-top: 10px;
  // }
  // super {
  //   color: #f00;
  // }
}
.bottomRow {
  display: block;
  text-align: center;
  width: 100%;
}
.goBtn {
  text-align: center;
  position: relative;
}

.phoneForm {
  composes: form;
}

.emailForm {
  composes: form;
}

.tabsWrapper {
  margin-right: 20px;
  >div>div:first-child { //nested classname BS
    justify-content: flex-end;
    & > button {
      text-indent: 22px;
    }
  }
}


.switchToLogin {
  font-size: 0.8rem;
  margin-right: 10px;
  opacity: 0.9;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.modal {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80vw;
  margin: 10vh auto 0;
  padding: 25px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  h3 {
    width: 100%;
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1.3rem;
  }
}

