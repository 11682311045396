.rotationWidget{
  display: block;
  padding: 0;
  position: relative;
}

.rotationWidgetScope{
  border: solid 1px rgba(255,255,255,0.5);
  width: 60%;
  padding-bottom: 60%;
  border-radius: 9999px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.rotationWidgetIndicator{
  background: rgba(255,255,255,0.6);
  width: 50%;
  height: 1px;
  position: absolute;
  top: 50%;
  transform-origin:center right;
}