.PermsPrompt {
  p {
    font-size: 1.3em;
    line-height: 1.6em;
    letter-spacing: 0.05em;
  }
  .arrow {
    position: relative;
    width: 160px;
    top: -58px;
    left: 70px;
  }
  .bottomRow {
    display: flex;
    justify-content: center;
    margin-top: -35px;
    margin-bottom: 10px;
  }
}