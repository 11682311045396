
@import '../../globalVars';
.mapContainer {
  width: 100%;
  height: 600px;
  @media only screen and (max-width: $breakpointSm) {
    height: 400px;
  }
  display: block;
  margin-top: $topNavHeight;
}

.destinationIcon {
  display: block;
  width: 100px;
  height: 100px;
  background: url('../../Img/Icons/bottle2b.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  // transform: translate3d(-50%,-50%,0);
  left: -50px;
  top: -50px;
}

.userIcon {
  display: block;
  width: 50px;
  height: 50px;
  background: url('../../Img/Icons/ghost2.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  // transform: translate3d(-50%,-50%,0);
  left: -25px;
  top: -25px;
}

.letsGo{
  display: inline-block;
  position: relative;
  top: -100px;
  width: 300px;
  margin: 0 auto;
  @media only screen and (max-width: $breakpointSm) {
    width: 200px;
    top: -45px;
  }
  img {
    position: relative;
    z-index: 2;
    width: 100%;
  }
}

.focusControls {
  display: flex;
  flex-direction: column;
}

.focusButton {
  display: block;
  width: 50px;
  height: 50px;
  z-index: 999;
  position: relative;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0em 0 0.4em 0 rgba(15, 22, 33, 60%);
  opacity: 0.5;
  &:first-of-type {
    margin-top: 0;
  }
}

.focusToUserButton {
  composes: focusButton;
  background-image: url('../../Img/Icons/MapFocusToUser.png'),linear-gradient(#fff,#fff);
  background-size: 56px,cover;
  background-position: -2.5px -2.5px, 0 0
}

.focusToBarButton {
  composes: focusButton;
  background-image: url('../../Img/Icons/MapFocusToBar.png'),linear-gradient(#fff,#fff);
  background-size: 56px,cover;
  background-position: -2.5px -2.5px, 0 0
}

.focusToRouteButton {
  composes: focusButton;
  background-image: url('../../Img/Icons/MapFocusToRoute.png'),linear-gradient(#fff,#fff);
  background-size: 56px,cover;
  background-position: -2.5px -2.5px, 0 0
}